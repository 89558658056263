import { IMAGE_PROXY_HOST } from "@app/constants"
import { getCoverURLBySize } from "./get-cover-url-by-size"

interface GetResponsiveSizesString {
  cover: string
  size: string
  isGoogleServiceAvailable: boolean
  imgFormat?: string
}

function getResponsiveSizesString({ cover, size, isGoogleServiceAvailable, imgFormat = "" }: GetResponsiveSizesString): string {
  const defaultResolutionCoverImage = getCoverURLBySize(cover, size)
  const highResolutionCoverImage = getCoverURLBySize(cover, `${size}@2`)
  let postfix = isGoogleServiceAvailable ? imgFormat : ""

  if (defaultResolutionCoverImage?.includes(IMAGE_PROXY_HOST)) {
    postfix = ""
  }

  if (!highResolutionCoverImage) {
    return `${defaultResolutionCoverImage}${postfix}`
  }

  if (highResolutionCoverImage) {
    return `${defaultResolutionCoverImage}${postfix} 1x, ${highResolutionCoverImage}${postfix} 2x`
  }
}

export { getResponsiveSizesString }
