import { useMemo } from "react"

import { isPublicGalleriesExist } from "@app/features/galleries/utils"
import { GalleryPreview, NoGalleries } from "@app/features/portfolio/components"
import { useSelectedTag } from "@app/features/tags/store/hooks"
import { Container } from "@app/ui/container"

import styles from "./galleries.module.scss"

const Galleries = ({ galleries }) => {
  const { selectedTag } = useSelectedTag()

  const filteredAndSortedGalleries = useMemo(() => {
    return galleries.filter((gallery) => {
      if (!selectedTag) return true
      return gallery.tags?.some((tag) => tag.id === selectedTag.id)
    })
    // .sort((a, b) => (b.pinned ? 1 : 0) - (a.pinned ? 1 : 0))
  }, [galleries, selectedTag])

  if (!isPublicGalleriesExist(galleries)) {
    return <NoGalleries />
  }

  return (
    <Container>
      <div className={styles["root"]}>
        {filteredAndSortedGalleries.map((gallery) => {
          if (!selectedTag) {
            return <GalleryPreview key={gallery.id} {...gallery} />
          }

          if (gallery?.tags?.find((tag) => tag.id === selectedTag?.id)) {
            return <GalleryPreview key={gallery.id} {...gallery} />
          }
        })}
      </div>
    </Container>
  )
}

export { Galleries }
